import React, { useEffect } from 'react';

export const Header = () => {
    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        const channel = currentUrl.searchParams.get("channel");
        if (channel) {
            window.dataLayer.push({
                event: "channel",
                button_title: channel
            });
        }
      }, []);
    const handleTopHome = () => {
        window.scrollTo(0, 0);
    }
    return (
        <div className='zindex p-0'>
            <div className='row justify-content-between justify-content-lg-evenly justify-md-conent-center align-items-center logoHeader'>
                <div className='col-12 px-4 mt-3'><img src="img/header.png" border="0" width="250" alt="ae" /></div>
            </div>
        </div>
    )
}