import React, { useState } from "react";

export const Social = ({ icon, url }) => {

    const handleClick = (e) => {
        e.preventDefault();
        window.open(url ? url : window.location.href, '_blank')
    }
    return (
        <>
            <span className="icon-style button-icon-social" onClick={handleClick}>
                {icon ? <img src={`img/${icon}.png`} width="100%"  /> : <i className="fa-solid fa-share-nodes iconshare"></i>}
            </span>
        </>
    )
}