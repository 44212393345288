import React, { useState } from "react";
import { InlineShareButtons } from 'sharethis-reactjs';

export const Links = ({ icon, url, text }) => {
    const [copied, setCopied] = useState(false);
    const copy = () => {
        window.dataLayer.push({
            event: "click",
            button_title: 'Compartir'
        });
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(!copied);
    }
    const handleClick = (e) => {
        e.preventDefault();
        window.dataLayer.push({
            event: "click",
            button_title: text ? text : 'Compartir'
        });
        window.open(url, '_blank')
    }
    return (
        <>
            <div className='linkComponent button-container cursor' onClick={url ? handleClick : copy}>
                <span className="icon-style button-icon">
                    {icon ? <img src={`img/${icon}.png`} width="40" height="40" alt={text} /> : <i className="fa-solid fa-share-nodes iconshare"></i>}
                </span>
                {url ? <span className="button-title">{text ? text : 'Compartir'}</span> : <span className="button-title">Compartir</span>}

                <span className="button-go">
                    IR
                </span>
            </div>
        </>
    )
}