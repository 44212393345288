import React from "react";
import { Header } from './components/landing/Header';
import { Links } from './components/links/Links';
import { Share } from './components/links/Share';
import { Video } from './components/landing/Video';

class App extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  render() {
  let widthScreen = window.screen.width;
  return (
    <div className="w-100">
      <div className='row p-0 m-0'>
        <div className='col-12 col-lg-6 col-xl-6 col-xxl-6 p-0 m-0 white'>
          <div className="align-items-start mb-4">
            <div className="d-flex align-items-center heightMax">
              <div className="cardStyle">
                <Header />
                <Video />
                <Share />
                <div className="shareBorder"></div>
                <Links text='Participa y Gana' icon='Concurso' url='https://aenetworkslatam.com/eventos/ae2024/' />
                <Links text='El poder de nuestras marcas' icon='A+E_icon' url='https://vimeo.com/872765767' />
                <Links text='User Creative Solutions' icon='UCS_icon' url='https://vimeo.com/876460274' />
                <Links text='Total Audience Plus' icon='TAP_icon' url='https://vimeo.com/875374181' />
                <Links text='A+E Cards' icon='A+ECard_icon' url='https://vimeo.com/875374088' />
                <Links text='A+E Video Rewards' icon='A+E_VReward_icon' url='https://vimeo.com/875373972' />
                <Links text='Youtube Partnership' icon='YouTube_icon' url='https://www.youtube.com/channel/UCVvwbez-BNlWvbxtpOx0MGQ' />
                <div className="mb-4">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-none d-lg-block col-12 col-lg-6 col-xl-6 col-xxl-6 p-0 vh-100'>
          
        </div>
      </div>
    </div>
  );
}
}

export default App;
